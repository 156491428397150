import { useEffect, useState } from "react";
import { navigateToUrl } from "single-spa";
import { FacturaPensionado } from "../components/facturaPension";
import {
  HeadTitle,
  Container,
  ReusableButton,
  PaginationAsulado,
  ModalAsulado,
  GoEye,
  FaRegFilePdf,
  DynamicTable,
  LoaderAsulado,
} from "@mfe/js-asulado-uiutils";
import { MsalProvider, msalInstance } from "@mfe/react-asulado-msal";
import { postService, getService, API } from "@mfe/ts-asulado-utilitaries";

export const Detalle = () => {
  return (
    <MsalProvider instance={msalInstance}>
      <DetalleComponent />
    </MsalProvider>
  );
};

const columns = [{ key: "nombre", label: "Nombre" }];
const rowsPerPage = 7;

const DetalleComponent = () => {
  const [tokenApi, setTokenApi] = useState({
    Authorization: `Bearer ${localStorage.getItem("access_token")}`,
  });
  const [isLoading, setIsLoading] = useState(true);
  const [liquidacionesAprovadas, setLiquidacionesAprovadas] = useState([]);
  const [datafacturaPensionado, setDataFacturaPensionado] = useState([]);
  const [modalDetailIsOpen, setModalDetailIsOpen] = useState(false);

  const [currentPage, setCurrentPage] = useState(1);
  const startIndex = (currentPage - 1) * rowsPerPage;
  const endIndex = startIndex + rowsPerPage;
  const paginatedData = liquidacionesAprovadas.slice(startIndex, endIndex);
  const totalPages = Math.ceil(liquidacionesAprovadas.length / rowsPerPage);

  const closeModalDetail = () => setModalDetailIsOpen(false);

  const handleClickReturn = () => {
    navigateToUrl("/app#aprobacion");
  };

  const handleViewDetail = (row) => {
    const data = {
      pen_codigo: row.codigoPensionado.toString(),
      liq_codigo: row.codigoLiquidacion.toString(),
    };

    const fetchQueryResults = async () => {
      try {
        const respuesta = await postService(
          `${API.simulacion}listarDetalleDescuentos`,
          data,
          tokenApi
        );

        if (typeof respuesta === "object") {
          setDataFacturaPensionado(respuesta);
        } else {
          console.error(`Error al listar detalle descuentos: ${respuesta}`);
        }
      } catch (error) {
        console.error("La consulta falló:", error);
      }
    };
    fetchQueryResults();
    setModalDetailIsOpen(true);
  };

  const handlePdf = () => {};

  useEffect(() => {
    const fetchQueryResults = async () => {
      try {
        const respuesta = await getService(
          `${API.simulacion}listarLiqAprobas`,
          tokenApi
        );

        if (typeof respuesta === "object") {
          setIsLoading(false);
          setLiquidacionesAprovadas(respuesta);
        } else {
          console.error(
            `Error al listar loquidaciones aprobadas: ${respuesta}`
          );
        }
      } catch (error) {
        console.error("La consulta falló:", error);
      } finally {
        setIsLoading(false);
      }
    };
    fetchQueryResults();
  }, []);

  return (
    <Container sx={{ maxWidth: "80%" }} size="xl">
      <HeadTitle title="Pensionados aprobados" />

      {isLoading ? (
        <div style={{ textAlign: "center", marginTop: 15 }}>
          <LoaderAsulado></LoaderAsulado>
        </div>
      ) : (
        <>
          <DynamicTable
            columns={columns}
            data={paginatedData}
            actions={"Acciones"}
            typeActions={[
              {
                metodo: handleViewDetail,
                icon: <GoEye size={20} />,
              },
              {
                metodo: handlePdf,
                icon: <FaRegFilePdf size={20} />,
              },
            ]}
          >
            <div className="pagination">
              <PaginationAsulado
                currentPage={currentPage}
                totalPages={totalPages}
                onPageChange={setCurrentPage}
              />
            </div>
          </DynamicTable>

          <div style={{ textAlign: "end", marginTop: 15 }}>
            <ReusableButton
              text="Volver"
              onClick={handleClickReturn}
              className="submit-btn"
            />
          </div>
        </>
      )}

      {modalDetailIsOpen && datafacturaPensionado.length > 0 && (
        <ModalAsulado className={"width50"}>
          <FacturaPensionado
            dataPensionado={datafacturaPensionado}
          ></FacturaPensionado>
          <div style={{ marginTop: 15, textAlign: "center" }}>
            <ReusableButton
              text="Cerrar"
              onClick={closeModalDetail}
              className="submit-btn"
            />
          </div>
        </ModalAsulado>
      )}
    </Container>
  );
};

export default DetalleComponent;
