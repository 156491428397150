import { useState } from "react";

import { images } from "@mfe/js-asulado-uiutils";
import { decryptDataUtil } from "@mfe/ts-asulado-utilitaries";

export const FacturaPensionado = ({ dataPensionado }) => {
  const [ciudades, setCiudades] = useState(
    JSON.parse(localStorage.getItem("ciudades"))
  );

  const fechaActual = new Date();
  const dia = fechaActual.getDate();
  const mes = fechaActual.getMonth() + 1;
  const anio = fechaActual.getFullYear();

  const fecha = `${dia}-${mes}-${anio}`;

  return (
    <div className="invoice">
      <div
        style={{
          display: "grid",
          gridTemplateColumns: "70% 1fr",
          alignItems: "baseline",
        }}
      >
        <h2>Liquidación Consolidada de Pensiones</h2>

        <div style={{ textAlign: "right" }}>
          <img style={{ width: "100%" }} src={images.logoAsulado} alt="image" />
        </div>
      </div>

      <div
        className="employee-info"
        style={{ textAlign: "right", marginBottom: 30 }}
      >
        <p>
          Fecha actual: <strong>{fecha}</strong>
        </p>
      </div>

      <div className="employee-info" style={{ textAlign: "right" }}>
        <p style={{ margin: 0, paddingBottom: 10 }}>
          Nombre:
          <strong>
            {` ${dataPensionado[0].nombrePensionado} ${dataPensionado[0].apellidoPensionado}`}
          </strong>
        </p>
        <p style={{ margin: 0, paddingBottom: 10 }}>
          Identificación: <strong>{'4518814'}</strong>
          {/* <strong>
            {` ${decryptDataUtil(
              "U2FsdGVkX1/+N6EiUJBZfrpVf67x/hk/c91lviv6fz4="
            )}`}
          </strong> */}
        </p>
        <p style={{ margin: 0, paddingBottom: 10 }}>
          Ciudad:{" "}
          <strong>
            {
              ciudades.find(
                (ciudad) =>
                  ciudad.codigoMunicipio === dataPensionado[0].codigoCiudad
              ).municipio
            }
          </strong>
        </p>
        <p style={{ margin: 0, paddingBottom: 10 }}>
          Periodo: <strong>{dataPensionado[0].periodo}</strong>
        </p>
      </div>

      <div className="payment-details" style={{ margin: "15px 0" }}>
        <table style={{ width: "100%", borderCollapse: "collapse" }}>
          <thead>
            <tr>
              <th
                style={{
                  border: "1px solid #ccc",
                  padding: "8px",
                  textAlign: "center",
                }}
              >
                Detalle
              </th>
              <th
                style={{
                  border: "1px solid #ccc",
                  padding: "8px",
                  textAlign: "center",
                }}
              >
                Cantidad
              </th>
              <th
                style={{
                  border: "1px solid #ccc",
                  padding: "8px",
                  textAlign: "center",
                }}
              >
                Valor
              </th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td style={{ border: "1px solid #ccc", padding: "8px" }}>
                Pensión
              </td>
              <td style={{ border: "1px solid #ccc", padding: "8px" }}>30</td>
              <td
                style={{
                  border: "1px solid #ccc",
                  padding: "8px",
                  textAlign: "right",
                }}
              >
                $ {dataPensionado[0].valorPension.toLocaleString("es-CO")}
              </td>
            </tr>

            <tr>
              <td style={{ border: "1px solid #ccc", padding: "8px" }}>
                Descuentos
              </td>
              <td style={{ border: "1px solid #ccc", padding: "8px" }}>30</td>
              <td
                style={{
                  border: "1px solid #ccc",
                  padding: "8px",
                  textAlign: "right",
                }}
              >
                $ -
                {dataPensionado[0].valorTotalDescuentos.toLocaleString("es-CO")}
              </td>
            </tr>

            {/* {dataPensionado.map((item, index) => (
              <tr key={index}>
                <td style={{ border: "1px solid #ccc", padding: "8px" }}>
                  {item.descripcionConcepto}
                </td>
                <td style={{ border: "1px solid #ccc", padding: "8px" }}>30</td>
                <td
                  style={{
                    border: "1px solid #ccc",
                    padding: "8px",
                    textAlign: "right",
                  }}
                >
                  $ {item.valorDescuento.toLocaleString("es-CO")}
                </td>
              </tr>
            ))} */}
          </tbody>
          <tfoot>
            <tr>
              <th></th>
              <th
                style={{
                  border: "1px solid #ccc",
                  padding: "8px",
                  textAlign: "left",
                }}
              >
                Total
              </th>
              <th
                style={{
                  border: "1px solid #ccc",
                  padding: "8px",
                  textAlign: "right",
                }}
              >
                $ {dataPensionado[0].valorTotal.toLocaleString("es-CO")}
              </th>
            </tr>
          </tfoot>
        </table>
      </div>
    </div>
  );
};
